var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{attrs:{"bodyClasses":"p-0 d-flex align-items-stretch"}},[_c('dl',{staticClass:"field"},[_c('dt',[(
          (_vm.position.priority_internal &&
            _vm.currentUserRights.includes(
              'im.details.priority.cansee-intern'
            )) ||
          _vm.position.priority_internal == false
        )?[(_vm.position.priority)?_c('el-tooltip',{attrs:{"placement":"right"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.position.priority_date))+": "+_vm._s(_vm.position.priority_reason)+" ")]),_c('i',{staticClass:"fas fa-bolt active-star mr-2"})]):_vm._e()]:_vm._e(),_vm._v(" "+_vm._s(_vm.$t("message.area"))+" ")],2),_c('dd',[_vm._v(" "+_vm._s(_vm.position.area ? _vm.position.area.name : "")+" "),(_vm.position.isPrioritised)?_c('el-tooltip',{staticClass:"item",attrs:{"content":"10.07.2019","effect":"dark","placement":"top-start"}},[_c('i',{staticClass:"fas fa-bolt faa-flash animated animated active-star"})]):_vm._e()],1)]),_c('dl',{staticClass:"field"},[_c('dt',[_vm._v(_vm._s(_vm.$t("message.position")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.position.bhb_position ? _vm.position.bhb_position.name : "")+" ")])]),_c('dl',{staticClass:"field"},[_c('dt',[_vm._v(_vm._s(_vm.$t("global.order")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.position.order ? _vm.position.order.No : "")+" ")])]),(_vm.currentUserRights.includes('BHB - Vollbild_Ansicht mit Preis'))?_c('dl',{staticClass:"field fill"},[(_vm.isCustomerAdr)?_c('dt',[_vm._v(_vm._s(_vm.$t("message.price")))]):_vm._e(),(_vm.isCustomerAdr)?_c('dd',[_vm._v(_vm._s(_vm._f("currency")(_vm.position.price_total)))]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"commands"},[(
        _vm.currentUserRights.includes(
          'bhb.status.can-modify-wirtschaftliche-freigabe'
        )
      )?_c('base-button',{attrs:{"size":"sm","type":_vm.position.rel_business ? 'success' : 'light',"disabled":_vm.project.state === 'ARCHIVED' ||
  (_vm.currentUserRights.includes('bhb.status.can-modify-state-once') &&
    _vm.position.rel_business)},on:{"click":_vm.handleCostApprovalClick}},[_vm._v(" "+_vm._s(_vm.$t("message.economic_release"))+" "),(_vm.position.rel_business)?_c('i',{staticClass:"ni ni-check-bold"}):_vm._e(),(_vm.position.rel_business)?_c('small',[_vm._v(" "+_vm._s(_vm.position.user_rel_business ? _vm.position.user_rel_business.name : "?")+" - "+_vm._s(_vm._f("datetime")((_vm.position.date_rel_business + " GMT")))+" ")]):_vm._e()]):_vm._e(),(_vm.currentUserRights.includes('bhb.status.can-modify-freigabe'))?_c('base-button',{attrs:{"size":"sm","type":_vm.isApproved ? 'success' : 'light',"disabled":_vm.isApproved ||
        _vm.project.state === 'ARCHIVED' ||
        (_vm.currentUserRights.includes('bhb.status.can-modify-state-once') &&
          _vm.nonResettableStates.includes(_vm.position.state_bhb))},on:{"click":_vm.handleDesignApprovalClick}},[_vm._v(" "+_vm._s(_vm.$t("message.release"))+" "),(_vm.isApproved)?_c('i',{staticClass:"ni ni-check-bold"}):_vm._e(),(_vm.isApproved)?_c('small',[_vm._v(" "+_vm._s(_vm.position.user_rel_content ? _vm.position.user_rel_content.name : "?")+" - "+_vm._s(_vm._f("datetime")((_vm.position.date_rel_content + " GMT")))+" ")]):_vm._e()]):_vm._e(),(_vm.currentUserRights.includes('bhb.status.can-modify-korrektur'))?_c('base-button',{attrs:{"size":"sm","type":_vm.isCorrected ? 'warning' : 'light',"disabled":_vm.isCorrected ||
        _vm.project.state === 'ARCHIVED' ||
        (_vm.currentUserRights.includes('bhb.status.can-modify-state-once') &&
          _vm.nonResettableStates.includes(_vm.position.state_bhb))},on:{"click":_vm.handleCorrectionClick}},[_vm._v(" "+_vm._s(_vm.$t("message.correction"))+" "),(_vm.isCorrected)?_c('i',{staticClass:"ni ni-check-bold"}):_vm._e()]):_vm._e(),(_vm.currentUserRights.includes('bhb.status.can-modify-handlung-pl'))?_c('base-button',{attrs:{"size":"sm","type":_vm.isActionPL ? 'warning' : 'light',"disabled":_vm.project.state === 'ARCHIVED'},on:{"click":_vm.handleActionPL}},[_vm._v(" "+_vm._s(_vm.$t("message.actionPL"))+" "),(_vm.isActionPL)?_c('i',{staticClass:"ni ni-check-bold"}):_vm._e()]):_vm._e(),(_vm.currentUserRights.includes('bhb.status.can-modify-in-produktion'))?_c('base-button',{attrs:{"size":"sm","type":_vm.isInProduction ? 'danger' : 'light',"disabled":_vm.isInProduction || _vm.project.state === 'ARCHIVED'},on:{"click":_vm.handleInProductionClick}},[_vm._v(" "+_vm._s(_vm.$t("message.inProduction"))+" "),(_vm.isInProduction)?_c('i',{staticClass:"ni ni-check-bold"}):_vm._e()]):_vm._e(),(_vm.currentUserRights.includes('bhb.status.can-modify-offen'))?_c('base-button',{attrs:{"size":"sm","type":_vm.isOpen ? 'danger' : 'light',"disabled":_vm.isOpen ||
        _vm.project.state === 'ARCHIVED' ||
        (_vm.currentUserRights.includes('bhb.status.can-modify-state-once') &&
          _vm.nonResettableStates.includes(_vm.position.state_bhb))},on:{"click":_vm.handleOpenClick}},[_vm._v(" "+_vm._s(_vm.$t("message.stateOpen"))+" "),(_vm.isOpen)?_c('i',{staticClass:"ni ni-check-bold"}):_vm._e()]):_vm._e(),(_vm.currentUserRights.includes('bhb.status.can-modify-ablehnen'))?_c('base-button',{attrs:{"size":"sm","type":_vm.isDeclined ? 'danger' : 'light',"disabled":_vm.isDeclined ||
        _vm.project.state === 'ARCHIVED' ||
        (_vm.currentUserRights.includes('bhb.status.can-modify-state-once') &&
          _vm.nonResettableStates.includes(_vm.position.state_bhb)) ||
        _vm.position.rel_business},on:{"click":_vm.handleDeclineClick}},[_vm._v(" "+_vm._s(_vm.$t("message.reject"))+" "),(_vm.isDeclined)?_c('i',{staticClass:"ni ni-check-bold"}):_vm._e(),(_vm.isDeclined)?_c('span',[_vm._v(" "+_vm._s(_vm.position.user_rejected ? _vm.position.user_rejected.name : "?")+" - "+_vm._s(_vm._f("datetime")((_vm.position.date_rejected + " GMT")))+" ")]):_vm._e()]):_vm._e(),(_vm.currentUserRights.includes('bhb.status.can-modify-montiert'))?_c('base-button',{attrs:{"size":"sm","type":"info"},on:{"click":_vm.handleMountedClick}},[_vm._v(" "+_vm._s(_vm.$t("message.mounted"))+" "),(_vm.isMounted)?_c('i',{staticClass:"ni ni-check-bold"}):_vm._e()]):_vm._e(),(_vm.currentUserRights.includes('bhb.status.can-modify-dv-erstellt'))?_c('base-button',{attrs:{"size":"sm","type":"info"},on:{"click":_vm.handleDvErstelltClick}},[_vm._v(" "+_vm._s(_vm.$t("message.dv-erstellt"))+" "),(_vm.isDvErstellt)?_c('i',{staticClass:"ni ni-check-bold"}):_vm._e()]):_vm._e()],1),_c('price-detail-modal',{attrs:{"show":_vm.showPriceDetailModal,"position":_vm.position},on:{"update:show":function($event){_vm.showPriceDetailModal=$event}}}),_c('correction-modal',{attrs:{"position":_vm.position,"show":_vm.showCorrectionModal},on:{"update:show":function($event){_vm.showCorrectionModal=$event},"created":_vm.handleCorrectionSent}}),_c('mounted-modal',{attrs:{"position":_vm.position,"show":_vm.showMountedModal},on:{"update:show":function($event){_vm.showMountedModal=$event},"created":_vm.handleMountedSent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }